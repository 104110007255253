import './home.css'
import Tower from './components/Tower'
import Lizard from './components/Lizard';


function home() {
    //*para mobile
    function isMobile() {
        const mq = window.matchMedia('(max-width: 480px)'); // Ajusta el breakpoint según sea necesario
        return mq.matches;
    }
    //*termina 
    return(
        <div className="container">
            <Lizard isMobile={isMobile}/>
            <Tower/>
        </div>
    )
}

export default home;