import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../components/lizard.css'

const Lizard = (ismobile) => {

    const [remLizard, setRemLizard] = useState(0)

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const arsValues = await axios.get('https://api.bluelytics.com.ar/v2/latest')
            const mgValue = await axios.get('https://api.coinbase.com/v2/prices/MAGAIBA-USD/sell')
            const height = setHeight(arsValues.data.blue.value_sell, Number(mgValue.data.data.amount));
            setRemLizard(height)
            console.log('soy mgValue en ars', Number(mgValue.data.data.amount) * arsValues.data.blue.value_sell, 'soy ArsValue', arsValues.data.blue.value_sell, 'soy height', height);
            scrollElement('lizard-container');
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    function setHeight(arsValue, mgValue) {
        switch (true) {
            case (mgValue <= (2 / arsValue)):
                console.log('soy lizard2');
                return convertPxToRem(105); //100 pq es la altura del billete $2
            case ((mgValue > (2 / arsValue)) && (mgValue < (5 / arsValue))):
                console.log('estoy en el medio de 2 y 5')
                return convertPxToRem(150);
            case mgValue == (5 / arsValue):
                console.log('soy lizard5');
                return convertPxToRem(220);
            case ((mgValue > (5 / arsValue)) && (mgValue < (10 / arsValue))):
                console.log('estoy en el medio de 5 y 10') //100 pq es la altura del billete $5
                return convertPxToRem(270)
            case mgValue == (10 / arsValue):
                console.log('soy lizard10');
                return convertPxToRem(305); //100 pq es la altura del billete $5
            default:
                break;
        }
    }

    function convertPxToRem(pxValue) {
        // Obtén el tamaño de fuente del elemento raíz (generalmente <html>)
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        // Calcula el valor en rem
        const remValue = pxValue / rootFontSize;
        return remValue;
    }


    function scrollElement(id) {
        const element = document.getElementById(id);
        element.scrollIntoView()
    }
    const styles = {
        image: {
            position: 'absolute',
            bottom: ismobile ? remLizard + 5.5 + 'vh' : remLizard + 'rem',
            /* margin-left: '9.06rem', */
            //width: '4.68rem',
            //marginleft: '9.25rem',
            //height: '4.1875rem',

        },
    };

    return (
        <div id='lizard-container' className='lizardDiv'>
            <img src='/Lizardampliado.png' style={styles.image} alt='lizard' />
        </div>
    );
};

export default Lizard;