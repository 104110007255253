
import './App.css';
import Home from './Home.jsx'

function App() {
  return (
    <div className="App">
      <Home></Home>
      
    </div>
  );
}

export default App;
