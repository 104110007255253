import '../components/Tower.css'

function Tower() {

    return (
        <div className='towerDiv'>
            <img src="/tower_de_billetes-transformed.png" alt='MKtower'></img>
        </div>
    )
}

export default Tower;